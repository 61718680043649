body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #000;
    color: #fff;
}

.container {
    padding: 50px;
    overflow: hidden; 
}

.left-column {
    float: left;
    width: 50%; 
    box-sizing: border-box; 
    padding-right: 10px; 
}

.right-column {
    float: right;
    width: 50%; 
    box-sizing: border-box; 
    padding-left: 10px;
}

.left-column img {
    max-width: 80%;
    height: auto;
    display: block;
    margin-top: 30px;
}

.left-column,
.right-column {
    width: 50%;
    max-width: 700px; 
    box-sizing: border-box;
    padding: 0 10px; 
}


a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

a:hover {
    opacity: 0.7;
}


@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/ProximaNova-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/ProximaNova-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lockal';
    src: url('./fonts/Lockal-RoughOblique.otf') format('opentype');
    font-weight: normal;
    font-style: oblique;
}

@font-face {
    font-family: 'Lockal';
    src: url('./fonts/Lockal-Regular.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

p {
    font-family: 'Proxima Nova', Arial, sans-serif;
    font-size: 15pt;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Lockal', Arial, sans-serif;
}

a {
    font-family: 'Lockal', Arial, sans-serif;
}

.mobile-image {
    width: 50%;
}
